import { Text } from '@amzn/storm-ui';
import { StatusIcon } from '../icons/statusIcon';
import { getIconByText, IconInterface, Icons } from '../icons/iconObject';
import styled from 'styled-components';
import { IconsCss } from '../../AppCSS';
import { FormattedMessage } from 'react-intl';
import { getLocalizedKey } from '../../config/localizationHelpers';

import React from 'react';

export const ColoredText = styled(Text)`
  color: ${(props: { color: string }) => props.color};
  padding-left: ${({ theme }) => theme.spacing.micro};
`;

export const LastUpdatedCss = styled(Text)`
  @media (max-width: 600px) {
    margin-bottom: ${({ theme }) => theme.spacing.micro};
    font-size: small;
  }
`;

export const LegendContainer = styled.div`
  margin-block-end: ${({ theme }) => theme.spacing.large};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const IconTextContainer = styled.div`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing.large};
  margin-bottom: ${({ theme }) => theme.spacing.micro};
  justify-content: space-between;
  @media (max-width: 600px) {
    margin-right: ${({ theme }) => theme.spacing.medium};
  }
`;

interface LegendProp {
  timeStamp: string;
}

function Legend(props: LegendProp) {
  const legendList = Object.keys(Icons).map((element, index) => {
    return (
      <React.Fragment key={index}>
        <IconTextContainer>
          <StatusIcon
            size="sm"
            type={Icons[element].type}
            color={Icons[element].color}
            aria-label={Icons[element].text}
            aria-hidden="true"
          />
          <ColoredText color={setTextColor(Icons[element])} fontSize="small">
            <FormattedMessage id={getLocalizedKey(Icons[element].text.toLowerCase())} />
          </ColoredText>
        </IconTextContainer>
      </React.Fragment>
    );
  });
  return (
    <LegendContainer>
      <LastUpdatedCss fontSize="base" textColor="secondary">
        <FormattedMessage id="last-updated" />
        {': '} {props.timeStamp}
      </LastUpdatedCss>
      <IconsCss>{legendList}</IconsCss>
    </LegendContainer>
  );
}

function setTextColor(icon: IconInterface) {
  if (icon === getIconByText('No known issues') || icon === getIconByText('Disrupted')) {
    return icon.color;
  }

  return 'Secondary';
}

export default Legend;
