import React from 'react';
import styled from 'styled-components';
import { MinimumMargins, FillCenterContainer } from '../../AppCSS';
import { Text } from '@amzn/storm-ui';
import { FormattedMessage } from 'react-intl';
import TranslatedMessages from '../../config/localizationHelpers';

export const ErrorMessage = styled.div`
  margin-block-start: ${({ theme }) => theme.spacing.small};
  margin-block-end: ${({ theme }) => theme.spacing.large};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ErrorText = styled(Text)`
  margin-block-end: ${({ theme }) => theme.spacing.base};
`;

function ErrorPage() {
  return (
    <>
      <MinimumMargins>
        <FillCenterContainer>
          <ErrorMessage>
            <ErrorText type="h2" fontSize="extraLarge" textColor="base" withMargin={true}>
              <FormattedMessage id="sorry" />
            </ErrorText>
            <ErrorText type="p" fontSize="medium" textColor="secondary">
              <FormattedMessage id="requested-page-not-found" />
            </ErrorText>
            <ErrorText type="p" fontSize="medium" textColor="secondary">
              <FormattedMessage id="redirect-to-status-dashboard" />
              <a href="/">
                <FormattedMessage id="home" />
              </a>{' '}
              <TranslatedMessages messages={['page']} startString=" " />{' '}
            </ErrorText>
          </ErrorMessage>
        </FillCenterContainer>
      </MinimumMargins>
    </>
  );
}

export default ErrorPage;
