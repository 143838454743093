import React from 'react';
import { useParams } from 'react-router-dom';
import BreadcrumbHeader from '../common/header/breadcrumbHeader';
import {
  FillCenterContainerEventHistory,
  FillCenterEventHistoryHeader,
  FlexBetweenContainer,
  MessageContainer,
  StyledFragment,
} from '../AppCSS';
import { useQueryToGetJsonFromS3 } from '../common/dataRetrieval/useAxiosWithHooks';
import { HistoryEventJson, StatusMessage } from '../common/dataRetrieval/getDisplayData';
import { getIconByText, StatusIconText } from '../common/icons/iconObject';
import { determineDuration, determineMostSevereStatusV1, getFormattedTimestamp } from '../config/helpers';
import { CardListItem, Divider, Text } from '@amzn/storm-ui';
import { StatusIcon } from '../common/icons/statusIcon';
import JsonFooter from '../common/statusCards/jsonFooter';
import { getBaseUrl, isLocalizationEnabled, PlatformMap } from '../config/constants';
import { useIntl } from 'react-intl';
import ErrorPage from '../common/errorPage/errorPage';
import LoadingPage from '../common/errorPage/loadingPage';
import { extractCategoryAndSubcategory, Region, StatusType } from '@amzn/ads_status_dashboard_website_common';
import TranslatedMessages, { getLocalizedRegion } from '../config/localizationHelpers';
import { getCurrentEnvironment } from '../config/environment';
import { rumService } from '../config/rumHelper';

export function EventHistoryPage() {
  const { id } = useParams();
  const [hashedId, region, categorySubcategory] = id.toString().split('-');
  const regionExpanded = Region[region as keyof Region];
  const categorySubcategoryClean = categorySubcategory.replaceAll(' ', '%20');
  const eventHistoryS3Location = `eventHistories/${hashedId}/${region}/${categorySubcategoryClean}`;
  const baseUrl = getBaseUrl();
  const intl = useIntl();
  const locale = intl.locale && isLocalizationEnabled[getCurrentEnvironment()] ? '/' + intl.locale : '';
  const url = baseUrl.split('eventHistory')[0] + '/' + eventHistoryS3Location + locale;
  const { isError, isLoading, data } = useQueryToGetJsonFromS3(url);

  const eventData = React.useMemo<HistoryEventJson>(() => {
    if (!isError && data) {
      const asJsonString = JSON.stringify(data);
      return JSON.parse(asJsonString);
    }
  }, [data, isError]);

  if (isLoading) {
    return LoadingPage();
  }

  if (isError && !isLoading) {
    rumService.recordError(new Error(`Event History data fetch error`), { url: url });
    return ErrorPage();
  }

  const latestStatus = eventData ? eventData.currentStatus : 'Resolved';
  const isResolved = latestStatus === 'Resolved';
  const inProgressOrResolved = isResolved ? 'Resolved' : 'In progress';
  const headerStatus = eventData ? determineMostSevereStatusV1(eventData.statusMessages).toString() : 'Resolved';
  const { category, subcategory } = extractCategoryAndSubcategory(categorySubcategory);
  const duration = eventData ? determineDuration(eventData.startTime, eventData.endTime, intl.formatMessage) : '';
  const isMobile = window.screen.width < 768 || window.innerWidth < 768;
  const icon = isResolved ? getIconByText('No known issues') : getIconByText(latestStatus as StatusIconText);
  const prePlatformKey = isResolved ? 'Affected' : 'Affects';

  //Get the history from the json endpoint
  //eslint-disable-next-line react-hooks/rules-of-hooks
  return (
    <>
      <StyledFragment style={{ backgroundColor: 'white' }}>
        <FillCenterEventHistoryHeader style={{ backgroundColor: 'white' }}>
          <BreadcrumbHeader
            activeRegion={regionExpanded.toString()}
            category={category}
            subcategory={subcategory || ''}
            status={inProgressOrResolved}
            headerStatus={headerStatus}
            duration={duration}
            isMobile={isMobile}
          />
        </FillCenterEventHistoryHeader>
      </StyledFragment>
      <StyledFragment style={{ minHeight: '100vh' }}>
        <FillCenterContainerEventHistory style={{ marginBottom: '180px' }}>
          <MessageContainer
            style={{
              alignItems: 'left',
              display: 'block',
              justifyContent: 'left',
              marginLeft: '20px',
              marginRight: '20px',
              paddingTop: '2px',
              paddingBottom: '2px',
            }}
          >
            {eventData && (
              <Text
                type="p"
                style={{ marginLeft: '10px' }}
                withMargin={false}
                textColor="secondary"
                id={`statusMessage`}
              >
                <TranslatedMessages
                  messages={[
                    prePlatformKey,
                    PlatformMap[eventData.systemImpacted.toString().toLowerCase()] ??
                      eventData.systemImpacted.toString().toLowerCase(),
                  ]}
                  separator=" "
                />
              </Text>
            )}
            <Text
              type="p"
              style={{ marginLeft: '10px', paddingBottom: '2px' }}
              withMargin={false}
              textColor="secondary"
              id={`statusMessage`}
            >
              <TranslatedMessages
                messages={['region-caps', getLocalizedRegion(region)]}
                separator=": "
                sentenceCase={false}
              />
            </Text>
            <MessageContainer
              style={{
                alignItems: 'center',
                display: 'block',
                justifyContent: 'left',
                marginLeft: '3px',
                paddingBottom: '0px',
              }}
            >
              <Text type="h3" withMargin={true} textColor="base">
                <StatusIcon
                  size="med"
                  type={icon.type}
                  color={icon.color}
                  aria-label={icon.text}
                  aria-hidden="true"
                  style={{ display: 'inline', alignItems: 'center', justifyContent: 'center', marginRight: '4px' }}
                />
                <TranslatedMessages messages={['latest-status', latestStatus + '-lowercase']} separator=": " />
              </Text>
            </MessageContainer>
            <FlexBetweenContainer style={{ display: 'block', paddingTop: '44px' }}>
              {/* eslint-disable-next-line array-callback-return */}
              <CardListItem ariaLabel="t" id="cardListItem1">
                {/* eslint-disable-next-line array-callback-return */}
                {eventData?.statusMessages.map((value: StatusMessage, key: number) => {
                  const valMessage = value.message ? value.message.length : 0;
                  const icon = getIconByText(value.statusType.toString() as StatusIconText);
                  if (valMessage > 0 && value.statusType !== StatusType.noissue) {
                    return (
                      <>
                        <Text
                          style={{ display: 'block', marginLeft: '2px' }}
                          fontSize="base"
                          withMargin={false}
                          textColor="base"
                          type="p"
                          id="statusMessage"
                        >
                          <strong>{getFormattedTimestamp(new Date(value.timestamp))}</strong>
                        </Text>
                        <StatusIcon
                          size="small"
                          type={icon.type}
                          color={icon.color}
                          aria-label={icon.text}
                          aria-hidden="true"
                          style={{
                            display: 'inline',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '4px',
                          }}
                        />
                        <Text style={{ display: 'inline', marginLeft: '2px' }} fontSize="base" textColor="base">
                          <TranslatedMessages messages={[value.statusType.toString()]} separator=" " />
                        </Text>
                        <Text
                          style={{ display: 'block', marginLeft: '2px' }}
                          fontSize="base"
                          withMargin={false}
                          textColor="base"
                          type="h4"
                          key={key}
                          id={key.toString()}
                        >
                          {value.message}
                        </Text>
                        {key !== eventData?.statusMessages.length - 1 && <Divider />}
                      </>
                    );
                  }
                })}
              </CardListItem>
            </FlexBetweenContainer>
          </MessageContainer>
        </FillCenterContainerEventHistory>
        <JsonFooter activeView="history" alignItems="center" />
      </StyledFragment>
    </>
  );
}
