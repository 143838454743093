import LanguageSwitcher from './languageSwitcher';
import { globe } from '@amzn/storm-ui-icons';
import { DropdownSectionContainer, UtilitiesIcon, UtilitiesIconButton } from './languageSwitcherCss';
import { Popper } from '@amzn/storm-ui';
import React, { useCallback, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { reverseLanguageMap } from '../../config/constants';

const CustomPopper = (props) => {
  const modifyPopoverZIndex = (theme) => {
    return {
      ...theme,
      popover: {
        ...theme.popover,
        zIndex: props.zIndex,
      },
    };
  };

  return (
    <ThemeProvider theme={modifyPopoverZIndex}>
      <Popper {...props} />
    </ThemeProvider>
  );
};

export function LanguageDropdown({ currentLocale, onLanguageChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <CustomPopper
      isOpen={isOpen}
      autoFocus={true}
      trigger={
        <UtilitiesIconButton onClick={handleToggle} active={isOpen} aria-haspopup={true} aria-expanded={isOpen}>
          <UtilitiesIcon type={globe} />
          <span>{reverseLanguageMap[currentLocale]}</span>
        </UtilitiesIconButton>
      }
      position="bottom"
      align="end"
      strategy="fixed"
      onCloseButtonClick={handleClose}
      spacing={-10}
      padding="none"
      zIndex={500}
      data-testid="language-dropdown"
    >
      <DropdownSectionContainer>
        <LanguageSwitcher currentLocale={currentLocale} onLanguageChange={onLanguageChange} onClose={handleClose} />
      </DropdownSectionContainer>
    </CustomPopper>
  );
}

export default LanguageDropdown;
