import { useLocation } from 'react-router-dom';
import RegionHeader from '../common/header/regionHeader';
import AlertDisplay from '../common/alerts/alerts';
import Legend from '../common/statusCards/legend';
import JsonFooter from '../common/statusCards/jsonFooter';
import StatusCards from '../common/statusCards/statusCards';
import { FillCenterContainer, MinimumMargins } from '../AppCSS';
import { useEffect, useState } from 'react';
import {
  HISTORY_JSON_URL,
  JSON_URL,
  NO_KNOWN_ISSUES,
  OUTAGE_CATEGORY,
  RegionExpandedToKey,
  categoriesForDisplay,
  showHistory,
  isLocalizationEnabled,
  HISTORY_TRANSLATED_JSON_URL,
  STATUS_TRANSLATED_JSON_URL,
  // STATUS_TRANSLATED_JSON_URL
} from '../config/constants';
import { determineMostSevereStatus, generateMonthYearKeys, getTimeStamp, returnMonthYear } from '../config/helpers';
import { getIconByText, IconInterface, StatusIconText } from '../common/icons/iconObject';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryWithFallback } from '../common/dataRetrieval/useAxiosWithHooks';
import { HistoryEventJson, StatusJson } from '../common/dataRetrieval/getDisplayData';
import _get from 'lodash/get';
import { ButtonGroup, ButtonGroupItem } from '@amzn/storm-ui';
import EventCard from '../messageHistoryCards/eventCard';
import LoadingPage from '../common/errorPage/loadingPage';
import ErrorPage from '../common/errorPage/errorPage';
import { Category, Region, SYSTEM_WIDE_OUTAGE } from '@amzn/ads_status_dashboard_website_common';
import { getCurrentEnvironment } from '../config/environment';
import { rumService } from '../config/rumHelper';

export function StatusDashboard() {
  const historyMonthKeys = generateMonthYearKeys(6);
  const [selectedView, setSelectedView] = useState('status');
  const [activeRegionName, setActiveRegionName] = useState<Region>('NA' as Region);

  const location = useLocation();
  const intl = useIntl();
  const locale = intl.locale && isLocalizationEnabled[getCurrentEnvironment()] ? '/' + intl.locale : '';
  useEffect(() => {
    if (location.state) {
      setActiveRegionName(RegionExpandedToKey[location.state.region] as Region);
      setSelectedView(location.state.page);
    }
  }, [location.state]);

  const handleChangeViewFilter = (selection) => {
    setSelectedView(selection);
    if (rumService) {
      try {
        rumService.recordEvent('ButtonClick', {
          eventName: `handleChange-${selection}`,
          locale: intl.locale,
          region: activeRegionName,
        });
      } catch (error) {
        console.error('Failed to record RUM event:', error);
      }
    }
  };

  const isHistory = selectedView === 'history';
  const isCurrentStatus = selectedView === 'status';
  const getUrl = (translatedUrl, defaultUrl) =>
    isLocalizationEnabled[getCurrentEnvironment()] ? `${translatedUrl}${locale}` : defaultUrl;

  const historyUrl = getUrl(HISTORY_TRANSLATED_JSON_URL, HISTORY_JSON_URL);
  const statusUrl = getUrl(STATUS_TRANSLATED_JSON_URL, JSON_URL);

  const {
    isSuccess: isSuccessStatus,
    isLoading: isStatusLoading,
    isError: isStatusError,
    data: statusDataFromQuery,
  } = useQueryWithFallback(statusUrl, JSON_URL);

  const {
    isSuccess: isSuccessHistory,
    isLoading: isHistoryLoading,
    isError: isHistoryError,
    data: historyDataFromQuery,
  } = useQueryWithFallback(historyUrl, HISTORY_JSON_URL);

  const statusData = React.useMemo<StatusJson[]>(() => {
    if (isSuccessStatus) {
      return statusDataFromQuery.status;
    } else if (isStatusError) {
      rumService.recordError(new Error(`Status data fetch error`));
    }
  }, [statusDataFromQuery, isSuccessStatus, isStatusError]);

  const historyData = React.useMemo<HistoryEventJson[]>(() => {
    if (isSuccessHistory) {
      return historyDataFromQuery;
    } else if (isHistoryError) {
      console.log('historyError');
      rumService.recordError(new Error(`History data fetch error`));
    }
  }, [isSuccessHistory, historyDataFromQuery, isHistoryError]);

  const mostSevereIconsMapForRegion = React.useMemo<Map<Region, IconInterface>>(() => {
    if (statusData) {
      const newMostSevereIconsMap = new Map<Region, IconInterface>();
      //finding the mostSevereIcon for each Region, then it is further passed to RegionHeader
      Object.values(Region).forEach((region) => {
        const statusInThisRegion = statusData.filter((item) => item.region === region);
        const mostSevereStatus = determineMostSevereStatus(statusInThisRegion);
        const mostSevereIcon = getIconByText(mostSevereStatus as StatusIconText);
        newMostSevereIconsMap.set(region, mostSevereIcon);
      });
      return newMostSevereIconsMap;
    } else {
      return new Map();
    }
  }, [statusData]);

  const outageStatus = React.useMemo(() => {
    if (statusData) {
      return statusData.filter((item) => item.region === Region[activeRegionName] && item.category === OUTAGE_CATEGORY);
    }
  }, [statusData, activeRegionName]);

  if (isStatusLoading || isHistoryLoading) {
    return <LoadingPage />;
  }

  if (isStatusError || isHistoryError) {
    return <ErrorPage />;
  }
  return (
    <>
      <RegionHeader
        activeRegion={activeRegionName}
        onRegionUpdate={(returnedAlert) => setActiveRegionName(returnedAlert)}
        mostSevereIconForRegion={mostSevereIconsMapForRegion}
      />
      <MinimumMargins>
        <FillCenterContainer>
          &nbsp;&nbsp; &nbsp;&nbsp;
          {showHistory() && (
            <ButtonGroup
              name="status-history-filter"
              label="Select a view"
              selectedValue={selectedView}
              onChange={handleChangeViewFilter}
              small={false}
            >
              <ButtonGroupItem value="status">
                <FormattedMessage id="current-status" />
              </ButtonGroupItem>
              <ButtonGroupItem value="history">
                <FormattedMessage id="history" />
              </ButtonGroupItem>
            </ButtonGroup>
          )}
          <AlertDisplay
            icon={getIconByText(_get(outageStatus, '[0].statusType', getIconByText(NO_KNOWN_ISSUES)))}
            description={_get(outageStatus, '[0].statusMessage', NO_KNOWN_ISSUES)}
          />
          <Legend timeStamp={getTimeStamp()}></Legend>
          {isCurrentStatus &&
            // eslint-disable-next-line array-callback-return
            categoriesForDisplay.map((category, index) => {
              const matchedStatus = statusData.filter(
                (item) => item.region === Region[activeRegionName] && item.category === Category[category]
              );
              if (category !== SYSTEM_WIDE_OUTAGE) {
                return (
                  <StatusCards
                    key={index}
                    regionName={activeRegionName}
                    sectionName={category as Category}
                    regionSectionStatus={matchedStatus}
                  />
                );
              }
            })}
          {isHistory &&
            historyMonthKeys.map((monthYearKey, index) => {
              const matchedStatuses = historyData.filter(
                (item) => item.region === activeRegionName && returnMonthYear(item.startTime) === monthYearKey
              );
              return (
                <EventCard
                  key={index}
                  regionName={activeRegionName}
                  monthSection={matchedStatuses}
                  monthYearKey={monthYearKey.toString()}
                />
              );
            })}
          <JsonFooter activeView={selectedView} alignItems="center" />
        </FillCenterContainer>
      </MinimumMargins>
    </>
  );
}
