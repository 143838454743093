import { getFormattedTimestamp } from './helpers';

export const DEFAULT_DATE = getFormattedTimestamp(new Date());

export const DEFAULT_PLATFORM = '';
export const DEFAULT_CATEGORY = '';
export const DEFAULT_REGION = '';
export const DEFAULT_STATUS_MESSAGE = '';
export const DEFAULT_SUB_CATEGORY = '';
export const DEFAULT_SYSTEM_IMPACTED = '';
export const DEFAULT_TIMESTAMP = getFormattedTimestamp(new Date());
export const DEFAULT_HASHED_EVENT_ID = '';
export const DEFAULT_URL = '';
export const DEFAULT_STATUS_MESSAGES = [];
