import styled from 'styled-components';
import { Button, Dropdown, Icon, TextButton } from '@amzn/storm-ui';

export const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.mini};
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.mini};
`;

export const ChangeLangButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.mini};
  margin-bottom: ${({ theme }) => theme.spacing.mini};
  margin-right: ${({ theme }) => theme.spacing.small};
  min-width: 100px; // Reduced from 120px
`;

export const StyledDropdown = styled(Dropdown)`
  margin-top: ${({ theme }) => theme.spacing.mini};
  margin-bottom: ${({ theme }) => theme.spacing.mini};
  margin-start: ${({ theme }) => theme.spacing.mini};
  align-items: center;
  min-width: 100px; // Reduced from 120px

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 0;
    width: 100%;
    z-index: 1000;
  }
`;

export const Switcher = styled.div`
  color: #6f7782;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: ${({ theme }) => theme.spacing.mini};
`;

export const DropdownContainer = styled.div`
  position: absolute;
  z-index: 1;

  && div[role='listbox'] {
    position: unset;
  }
`;

export const LanguageLabel = styled.span`
  font-size: 11px;
  color: #6f7782;
  line-height: 19px;
  margin: 0 0 14px 6px;
  text-align: left;
  margin-left: ${({ theme }) => theme.spacing.small};
  margin-bottom: ${({ theme }) => theme.spacing.mini};
`;

export const UtilitiesIconButton = styled(TextButton)<{
  active?: boolean;
  isNotificationButton?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  border-radius: 5px;
  margin: 0 5px;
  color: white;
  position: ${({ isNotificationButton }) => (isNotificationButton ? 'relative' : 'static')};
  padding: 0 10px;
  white-space: nowrap;
  text-decoration: none;
  ${({ active, theme }) =>
    active &&
    `
    opacity: 1;
  `}
  &:active {
    transform: scale(0.95);
    transition-duration: 0.1s;
  }

  &:hover {
    opacity: 1;
    text-decoration: none;
  }

  // Style for the icon
  svg {
    margin-right: 5px; // Add some space between the icon and text
  }

  // Style for the text
  span {
    font-size: 13px; // Adjust the font size as needed
    font-weight: bold;
  }
`;

export const UtilitiesIcon = styled(Icon)`
  font-size: 15px;
`;

export const DropdownSectionContainer = styled('div')`
  font-size: 13px;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
`;
