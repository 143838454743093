import { Link, Text, Icon, defaultTheme } from '@amzn/storm-ui';
import React from 'react';
import styled from 'styled-components';
import { FlexBetweenContainer } from '../../AppCSS';

export const JsonFooterContainer = styled.div`
  margin-block-end: ${({ theme }) => theme.spacing.large};
  display: flex;
  justify-content: right;
  padding: 10px;
  background-color: #f2f2f2;
`;
export const RssIcon = styled(Icon)`
  color: ${defaultTheme.palette.orange[600]};
  margin-right: 3px;
`;

export interface JsonFooterProps {
  activeView: string;
  alignItems: string;
}

function JsonFooter(props: JsonFooterProps) {
  const isPreview = window.location.href.includes('preview');
  const isHistory = props.activeView === 'history';
  let fileLoc: string | undefined;
  let hyperlink;
  let schemaLink: string | undefined;
  if (isHistory) {
    fileLoc = '/history.json';
    hyperlink = 'History JSON';
    schemaLink = '/history.schema.json';
  } else {
    fileLoc = '/status.json';
    hyperlink = 'Status JSON';
    schemaLink = '/status.schema.json';
  }
  return (
    <>
      {!isPreview && (
        <JsonFooterContainer style={{ justifyContent: props.alignItems }}>
          <FlexBetweenContainer>
            <Text fontSize="base" textColor="base">
              <Link href={fileLoc} id="statusJson">
                {hyperlink}
              </Link>
            </Text>
            <Text fontSize="base" textColor="base">
              &nbsp;|&nbsp;
            </Text>
            <Text fontSize="base" textColor="base">
              <Link href={schemaLink} id="schemaJson">
                Schema
              </Link>
            </Text>
            <Text fontSize="base" textColor="base">
              &nbsp;|&nbsp;
            </Text>
            <RssIcon aria-hidden="true" type="rss-square" />
            <Text fontSize="base" textColor="base">
              <Link href="/status-feed.rss" id="rss">
                RSS
              </Link>
            </Text>
          </FlexBetweenContainer>
        </JsonFooterContainer>
      )}
    </>
  );
}

export default JsonFooter;
