import { TouchLink, Link, Text } from '@amzn/storm-ui';
import { StatusIcon } from '../common/icons/statusIcon';
import { getIconByText, StatusIconText } from '../common/icons/iconObject';
import { FlexBetweenContainer } from '../AppCSS';
import { HistoryEventJson } from '../common/dataRetrieval/getDisplayData';
import { determineMostSevereStatusV1, determineDuration } from '../config/helpers';
import { StyledCard } from './eventCard';
import { PlatformMap } from '../config/constants';
import { Region } from '@amzn/ads_status_dashboard_website_common';
import { useIntl } from 'react-intl';
import TranslatedMessages, {
  getLocalizedCategory,
  getLocalizedKey,
  getLocalizedPlatform,
} from '../config/localizationHelpers';

export interface MonthlyDisplayProps {
  displayDivider: boolean;
  regionName: Region;
  historyEvent: HistoryEventJson;
}

function MonthlyDisplay(props: MonthlyDisplayProps) {
  const { regionName, historyEvent } = props;
  const intl = useIntl();
  const isResolved = historyEvent.statusMessages[0].statusType === 'Resolved';
  const currStatus = isResolved ? 'Resolved:' : 'In progress:';
  const isMobile = window.screen.width < 768 || window.innerWidth < 768;
  const latestMessageDetails = historyEvent.currentMessage ? historyEvent.currentMessage : '';
  const headerStatus = determineMostSevereStatusV1(historyEvent.statusMessages);

  const icon = isResolved
    ? getIconByText('No known issues')
    : getIconByText(historyEvent.currentStatus as StatusIconText);
  const eventHistoryLink = `${historyEvent.url}`;
  const duration = determineDuration(historyEvent.startTime, historyEvent.endTime, intl.formatMessage);
  const prePlatformKey = isResolved ? 'affected' : 'affects';

  return (
    <>
      {isMobile && (
        <FlexBetweenContainer style={{ paddingBottom: '20px', justifyContent: 'start' }}>
          <TouchLink href={eventHistoryLink}>
            <StatusIcon
              size="sm"
              type={icon.type}
              color={icon.color}
              aria-label={icon.text}
              id={`status-${regionName}`}
              aria-hidden="true"
              style={{ padding: '2.5px', display: 'inline', alignItems: 'center', justifyContent: 'center' }}
            />
            <Text
              type="h4"
              fontSize="small"
              textColor="base"
              id={`category`}
              style={{ display: 'inline', paddingBottom: '60px' }}
            >
              <strong>
                <TranslatedMessages messages={[currStatus]} startString="  " bookendString=" " />
              </strong>
            </Text>
            <Text type="h4" fontSize="small" textColor="base" id={`category`} style={{ display: 'inline' }}>
              {historyEvent.subcategory ? (
                <TranslatedMessages
                  messages={[
                    getLocalizedCategory(historyEvent.category),
                    historyEvent.subcategory + '-lowercase',
                    getLocalizedKey(headerStatus.toString() + '-lowercase'),
                  ]}
                  separator=" "
                  startString=" "
                />
              ) : (
                <TranslatedMessages
                  messages={[
                    getLocalizedCategory(historyEvent.category),
                    getLocalizedKey(headerStatus.toString() + '-lowercase'),
                  ]}
                  separator=" "
                  startString=" "
                />
              )}
            </Text>
            <Text
              type="p"
              fontSize="small"
              textColor="base"
              id={`message`}
              style={{ border: '5px', paddingBottom: '6px', paddingTop: '6px' }}
            >
              {latestMessageDetails}
            </Text>
            <>
              <Text type="p" fontSize="small" textColor="base" id={`experience-${historyEvent.category}`}>
                <TranslatedMessages
                  messages={[prePlatformKey, PlatformMap[historyEvent.systemImpacted.toLowerCase()]]}
                  separator=" "
                />
              </Text>
              <Text type="p" fontSize="small" textColor="base" id={`start time`}>
                {duration}
              </Text>
            </>
          </TouchLink>
        </FlexBetweenContainer>
      )}
      {!isMobile && (
        <StyledCard padding="large">
          <FlexBetweenContainer
            key={regionName}
            style={{ alignItems: 'left', display: 'flex', justifyContent: 'start' }}
          >
            <StatusIcon
              size="small"
              type={icon.type}
              color={icon.color}
              aria-label={icon.text}
              id={`status-${regionName}`}
              aria-hidden="true"
              style={{ padding: '2.5px', display: 'inline', alignItems: 'center', justifyContent: 'center' }}
            />
            <Text type="h4" fontSize="small" textColor="base" id={`category`} style={{ display: 'inline' }}>
              <strong>
                <TranslatedMessages messages={[currStatus]} startString=" " bookendString=" " />
              </strong>
            </Text>
            <Text type="h4" fontSize="small" textColor="base" id={`category`} style={{ display: 'inline' }}>
              {historyEvent.subcategory ? (
                <TranslatedMessages
                  messages={[
                    getLocalizedCategory(historyEvent.category),
                    historyEvent.subcategory + '-lowercase',
                    getLocalizedKey(headerStatus.toString() + '-lowercase'),
                  ]}
                  separator=" "
                  startString=" "
                />
              ) : (
                <TranslatedMessages
                  messages={[
                    getLocalizedCategory(historyEvent.category),
                    getLocalizedKey(headerStatus.toString() + '-lowercase'),
                  ]}
                  separator=" "
                  startString=" "
                />
              )}
            </Text>
          </FlexBetweenContainer>
          <>
            <Text type="p" fontSize="small" textColor="base" id={`message`}>
              {latestMessageDetails}
            </Text>
            <Text fontSize="small" style={{ marginBottom: '5px' }}>
              <Link href={eventHistoryLink}>
                <TranslatedMessages messages={['see issue details']} startString=" " bookendString=" " />
              </Link>
            </Text>
          </>
          <>
            <Text
              type="p"
              fontSize="small"
              textColor="base"
              id={`experience-${historyEvent.subcategory}-${historyEvent.subcategory}`}
            >
              <TranslatedMessages
                messages={[
                  prePlatformKey,
                  getLocalizedPlatform(PlatformMap[historyEvent.systemImpacted.toLowerCase()]),
                ]}
                separator=" "
              />
            </Text>
            <Text type="p" fontSize="small" textColor="base" id={`start time`}>
              {duration}
            </Text>
          </>
        </StyledCard>
      )}
    </>
  );
}

export default MonthlyDisplay;
