import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import { useQuery } from 'react-query';
import { rumService } from '../../config/rumHelper';
import { publishMetric } from '../../config/metricPublisher';

export async function useAxiosToGetJsonFromS3(url: string) {
  try {
    axios.defaults.headers.common = {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    };

    const { data: response } = await axios.get(url);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export function useQueryToGetJsonFromS3(url) {
  return useQuery(
    ['jsonFromS3', url],
    async () => {
      try {
        rumService.recordEvent('QueryGetJson', { url });
        const { data: response } = await axios.get(url);
        return response;
      } catch (error) {
        throw error;
      }
    },
    {
      retry: false,
    }
  );
}

// Configure axios-retry
axiosRetry(axios, {
  retries: 3, // number of retries
  retryDelay: (retryCount) => {
    return retryCount * 1000; // time interval between retries
  },
  retryCondition: (error: unknown) => {
    if (axios.isAxiosError(error)) {
      // retry on network errors or 5xx status codes
      return (
        axiosRetry.isNetworkOrIdempotentRequestError(error) ||
        (error.response?.status !== undefined && error.response.status >= 400)
      );
    }
    return false;
  },
});

export function useQueryWithFallback(primaryUrl: string, fallbackUrl: string) {
  return useQuery(
    ['jsonFromS3', primaryUrl, fallbackUrl],
    async () => {
      try {
        rumService.recordEvent('QueryGetJson', { url: primaryUrl });
        const { data: response } = await axios.get(primaryUrl);

        // Publish CW metric
        await publishMetric({
          namespace: 'UrlFailures',
          metricName: 'UrlStatus',
          value: 0,
          dimensions: [{ Name: 'Failures', Value: 'Count' }],
        });
        return response;
      } catch (error) {
        const primaryError = error as Error | AxiosError;
        rumService.recordError(new Error(`PrimaryUrlFailure: ${primaryError.message}`), { url: primaryUrl });

        // Publish failure metric for the primary URL
        await publishMetric({
          namespace: 'UrlFailures',
          metricName: 'UrlStatus',
          value: 1,
          dimensions: [{ Name: 'Failures', Value: 'Count' }],
        });

        // If primary URL fails after retries, try fallback URL
        try {
          rumService.recordEvent('QueryGetJson', { url: fallbackUrl });
          const { data: fallbackResponse } = await axios.get(fallbackUrl);
          return fallbackResponse;
        } catch (error) {
          const fallbackError = error as Error | AxiosError;
          rumService.recordError(new Error(`FallbackUrlFailure: ${fallbackError.message}`), { url: fallbackUrl });

          // Publish failure metric for the fallback URL
          await publishMetric({
            namespace: 'UrlFailures',
            metricName: 'UrlStatus',
            value: 1,
            dimensions: [{ Name: 'Failures', Value: 'Count' }],
          });

          throw new Error('Both primary and fallback URLs failed');
        }
      }
    },
    {
      retry: false,
    }
  );
}
