import { Icon } from '@amzn/storm-ui';
import styled from 'styled-components';

export const StatusIcon = styled(Icon)`
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  width: 14px;
  justify-content: center;
  padding: 'small';
`;
