import { Breadcrumb, BreadcrumbGroup, Text } from '@amzn/storm-ui';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import TranslatedMessages, { getLocalizedCategory, getLocalizedRegion } from '../../config/localizationHelpers';
import { FormattedMessage } from 'react-intl';

const BreadcrumbHeaderStyling = styled.div`
  margin-inline-start: ${({ theme }) => theme.spacing.large};
  padding-inline-start: ${({ theme }) => theme.spacing.large};
  margin-inline-end: ${({ theme }) => theme.spacing.large};
  color: floralwhite;
  margin-top: 20px;
  align-items: end;
  margin-bottom: 20px;
`;

export interface BreadcrumbHeaderProps {
  activeRegion: string;
  category: string;
  subcategory: string;
  status: string;
  headerStatus: string;
  duration: string;
  isMobile: boolean;
}

function BreadcrumbHeader(props: BreadcrumbHeaderProps) {
  const { activeRegion, category, subcategory, status, headerStatus, duration } = props;
  const messagesArray = [
    getLocalizedCategory(category),
    ...(subcategory !== '' ? [subcategory + '-lowercase'] : []),
    headerStatus + '-lowercase',
  ];
  return (
    <BreadcrumbHeaderStyling>
      <BreadcrumbGroup>
        <Breadcrumb>
          <Link to={{ pathname: '/', state: { region: activeRegion, page: 'status' } }}>
            {<FormattedMessage id={getLocalizedRegion(activeRegion)}></FormattedMessage>}
          </Link>
        </Breadcrumb>
        <Breadcrumb>
          <Link to={{ pathname: '/', state: { region: activeRegion, page: 'history' } }}>
            {<FormattedMessage id="status-history"></FormattedMessage>}
          </Link>
        </Breadcrumb>
        {!props.isMobile && (
          <Breadcrumb>
            <TranslatedMessages messages={[status]} startString="[" bookendString="] " />
            <TranslatedMessages messages={messagesArray} separator=" " />
          </Breadcrumb>
        )}
      </BreadcrumbGroup>
      <Text type="p" fontSize="large" textColor="base" style={{ fontWeight: 'bold', paddingTop: '10px' }}>
        <TranslatedMessages messages={[status]} startString="[" bookendString="] " />
        <TranslatedMessages messages={messagesArray} separator=" " />
      </Text>
      <Text type="p" fontSize="small" textColor="secondary" style={{ fontWeight: 'normal', paddingTop: '4px' }}>
        {duration}
      </Text>
    </BreadcrumbHeaderStyling>
  );
}

export default BreadcrumbHeader;
