import { Card, CardHeader, Text } from '@amzn/storm-ui';
import CategoryDisplay from './categoryDisplay';
import { StatusIcon } from '../icons/statusIcon';
import { useEffect, useState } from 'react';
import { getIconByText, IconInterface, returnMostSevereIcon, StatusIconText } from '../icons/iconObject';
import styled from 'styled-components';
import { FlexBetweenContainer } from '../../AppCSS';
import { NO_KNOWN_ISSUES, categoryMapForDisplay } from '../../config/constants';
import React from 'react';
import { StatusJson } from '../dataRetrieval/getDisplayData';
import { Category, Region, SubCategory } from '@amzn/ads_status_dashboard_website_common';
import { FormattedMessage } from 'react-intl';
import { getLocalizedCategory } from '../../config/localizationHelpers';

export const StyledCard = styled(Card)`
  margin-bottom: ${({ theme }) => theme.spacing.large};
`;

export interface StatusCardsProps {
  regionName: Region;
  sectionName: Category;
  regionSectionStatus: Array<StatusJson>;
}

function StatusCards(props: StatusCardsProps) {
  const { sectionName, regionName, regionSectionStatus } = props;

  const listOfSubCategories = categoryMapForDisplay[Category[sectionName]];
  const [mostSevereIcon, setMostSevereIcon] = useState(getIconByText(NO_KNOWN_ISSUES));
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    if (regionSectionStatus) {
      // Find the most severe status among each Category
      let icon: IconInterface = getIconByText(NO_KNOWN_ISSUES);
      regionSectionStatus.forEach((item) => {
        icon = returnMostSevereIcon(getIconByText(item.statusType as StatusIconText), icon);
      });
      setIsCollapsed(icon === getIconByText(NO_KNOWN_ISSUES));
      setMostSevereIcon(icon);
    }
  }, [regionSectionStatus, regionName, sectionName]);

  return (
    <div>
      <StyledCard
        collapsable
        isCollapsed={isCollapsed}
        handleCollapseChanged={() => setIsCollapsed(!isCollapsed)}
        padding="large"
      >
        <CardHeader id={`${regionName}-${sectionName}`}>
          <FlexBetweenContainer>
            <Text type="h2">
              <FormattedMessage id={getLocalizedCategory(Category[sectionName])} />
            </Text>
            <StatusIcon
              size="lg"
              type={mostSevereIcon.type}
              color={mostSevereIcon.color}
              aria-label={mostSevereIcon.text}
              aria-hidden="true"
            />
          </FlexBetweenContainer>
        </CardHeader>
        {listOfSubCategories &&
          listOfSubCategories.map((value: SubCategory, key: number) => {
            console.log(value);
            const matchedStatus = regionSectionStatus.filter((item) => item.subcategory === value);
            return (
              <CategoryDisplay
                key={key}
                displayDivider={key < listOfSubCategories.length - 1}
                subCategory={value}
                regionName={regionName}
                sectionName={sectionName}
                status={matchedStatus[0]}
              />
            );
          })}
      </StyledCard>
    </div>
  );
}

export default StatusCards;
