import adsStatusLogoPng from '../resources/amazon_ads_logo.png';
import { StyledFragment } from '../AppCSS';
import { Header, AmazonAdsLogo, HeaderText, LanguageDropdownContainer } from './header/headerCSS';
import { FormattedMessage } from 'react-intl';
import LanguageDropdown from './header/LanguageDropdown';
import { isLocalizationEnabled } from '../config/constants';
import { getCurrentEnvironment } from '../config/environment';

export function Page(props) {
  const showSwitcher = isLocalizationEnabled[getCurrentEnvironment()];

  return (
    <StyledFragment style={{ minHeight: '100vh' }}>
      <Header>
        <a href="https://advertising.amazon.com/">
          <AmazonAdsLogo src={adsStatusLogoPng} alt="Logo" />
        </a>
        <HeaderText fontSize="medium">
          <FormattedMessage id="static-status" />
        </HeaderText>
        {/* Language dropdown menu */}
        {showSwitcher && (
          <LanguageDropdownContainer>
            <LanguageDropdown currentLocale={props.currentLocale} onLanguageChange={props.handleChange} />
          </LanguageDropdownContainer>
        )}
      </Header>
      <div>{props.content}</div>
    </StyledFragment>
  );
}
