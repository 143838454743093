import { Region, StatusType } from '@amzn/ads_status_dashboard_website_common';
import { RegionDisplay } from '../header/regionHeader';

export interface StatusJson {
  readonly category: string;
  readonly region: string;
  readonly statusMessage: string;
  readonly statusType: string;
  readonly subcategory: string;
  readonly systemImpacted: string;
  readonly timestamp: string;
}

export interface HistoryEventJson {
  readonly id: string;
  readonly region: string;
  readonly category: string;
  readonly subcategory?: string;
  readonly currentStatus: string;
  readonly currentMessage?: string;
  readonly statusMessages: StatusMessage[];
  readonly systemImpacted: string;
  readonly endTime?: string;
  readonly startTime: string;
  readonly url: string;
}

export interface StatusMessage {
  message?: string;
  timestamp: string;
  statusType: StatusType;
  systemImpacted: string; // or if there's a type
}

/**
 * Used for validation that Object is a Json Object. Most helpful when retrieving URLs
 * @param object typically a JSON object from the S3 bucket
 * @returns boolean where true means it has the necessary properties to be a Json
 */
export function isStatusJson(object: any): boolean {
  return (
    Object.prototype.hasOwnProperty.call(object, 'status') &&
    Object.prototype.hasOwnProperty.call(object, 'language') &&
    Object.prototype.hasOwnProperty.call(object, 'timestamp')
  );
}

/**
 * Creates the default region header without any alert messages displayed
 * @returns list of RegionDisplayProps[] to be used for <RegionHeader>
 */
export function getDefaultRegionHeader(): RegionDisplay[] {
  let regionDisplayList: RegionDisplay[] = [];
  for (const regionName in Region) {
    regionDisplayList.push({
      regionName: regionName as Region,
    });
  }
  return regionDisplayList;
}
