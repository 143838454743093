export enum Environment {
  Local = 'local',
  Alpha = 'alpha',
  Beta = 'beta',
  Production = 'prod',
}

export const getCurrentEnvironment = (): Environment => {
  if (
    window.location.hostname.toLowerCase().includes('localhost') ||
    window.location.hostname.toLowerCase().includes('dev-dsk')
  ) {
    return Environment.Local;
  }

  const parts = window.location.hostname.split('.');
  if (parts.includes(Environment.Alpha)) {
    return Environment.Alpha;
  } else if (parts.includes(Environment.Beta)) {
    return Environment.Beta;
  } else {
    return Environment.Production;
  }
};

export const isDevelopment = () => {
  const env = getCurrentEnvironment();

  return env === Environment.Local || env === Environment.Alpha || env === Environment.Beta;
};

export const isProduction = () => {
  const env = getCurrentEnvironment();
  return env === Environment.Production;
};

export default getCurrentEnvironment();
