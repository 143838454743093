import { CloudWatchClient, PutMetricDataCommand, Dimension, StandardUnit } from '@aws-sdk/client-cloudwatch';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { getRumConfig } from './rumStages';

interface MetricOptions {
  namespace: string;
  metricName: string;
  value: number;
  dimensions?: Dimension[];
}

/**
 * Publishes a metric to CloudWatch.
 * @param options Object containing metric details
 */
export async function publishMetric({ namespace, metricName, value, dimensions = [] }: MetricOptions) {
  const { identityPoolId } = getRumConfig();
  const credentials = fromCognitoIdentityPool({
    clientConfig: {
      region: 'us-east-1',
    },
    identityPoolId, // Using the identityPoolId from the configuration to get the credentials
  });

  const creds = await credentials();
  const command = new PutMetricDataCommand({
    Namespace: namespace,
    MetricData: [
      {
        MetricName: metricName,
        Dimensions: dimensions,
        Value: value,
        Unit: StandardUnit.Count,
      },
    ],
  });
  const cloudwatch = new CloudWatchClient({ credentials: creds, region: 'us-east-1' });

  try {
    await cloudwatch.send(command);
  } catch (err) {
    console.error('Failed to publish metric:', err);
  }
}
