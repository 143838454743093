import { Region } from '@amzn/ads_status_dashboard_website_common';
import { FormattedMessage } from 'react-intl';
import React from 'react';

const reverseRegionMap = Object.fromEntries(Object.entries(Region).map(([key, value]) => [value, key]));
const M_AND_R = 'Measurement & Reporting';
const REGION_PREFIX = 'region-';

export interface TranslationProps {
  messages: string[];
  startString?: string;
  bookendString?: string;
  separator?: string;
  sentenceCase?: boolean;
}

export function getLocalizedRegion(region: string) {
  if (region in reverseRegionMap) {
    return REGION_PREFIX + reverseRegionMap[region].toString();
  }
  return REGION_PREFIX + region;
}

export function getLocalizedCategory(category: string) {
  if (category === M_AND_R) {
    return 'measurement-and-reporting';
  } else {
    return getLocalizedKey(category);
  }
}

/**
 * A function to handle the case where console and api translates from api-and-console.
 * Because of how the front end renders console-and-api to 'API and console', we need to account for both.
 * @todo: Remove this when we can safely ensure consistency from backend to front end (console-and-api).
 * @param platform The platform to be localized
 * @returns The localized platform name
 */
export function getLocalizedPlatform(platform: string) {
  const allLowercase = getLocalizedKey(platform);
  if (allLowercase === 'console-and-api') {
    return 'api-and-console';
  }
  return allLowercase;
}

export function getLocalizedKey(anyKey: string) {
  if (anyKey.includes('region')) {
    return anyKey;
  }
  return anyKey.toLowerCase().replaceAll(' ', '-').replaceAll(':', '');
}

/**
 * TranslatedMessages is a React component that takes a list of messages and translates them using the FormattedMessage component from react-intl.
 * Translation props takes in an array of terms, each of which is a message key to be translated using the FormattedMessage component.
 * It also allows for optional startString, which is placed before the first term in the array, a
 * bookendString which appends to the end of the last term, and separator parameters which are added between.
 * @param props
 * @returns React component with translated messages keys and specific formatting.
 */
function TranslatedMessages(props: TranslationProps) {
  const { messages, startString, bookendString, separator } = props;
  const translatedMessages = messages.map((message, index) => (
    <React.Fragment key={index}>
      {index === 0 && startString === ' ' ? '\u00A0' : startString}
      {<FormattedMessage id={getLocalizedKey(message)} />}
      {index === messages.length - 1 && bookendString}
      {index !== messages.length - 1 && separator}
    </React.Fragment>
  ));
  return <>{translatedMessages}</>;
}

export default TranslatedMessages;
