import { AwsRum, AwsRumConfig } from 'aws-rum-web';

import { getRumConfig } from './rumStages';

let rumClient: AwsRum;

export type RumConfig = {
  appId: string;
  identityPoolId: string;
  guestRoleArn: string;
};

const initRum = (rumConfig: RumConfig) => {
  try {
    const { appId, identityPoolId, guestRoleArn } = rumConfig;
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn,
      identityPoolId,
      endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
      telemetries: ['performance', 'errors', 'http', 'custom', 'interaction', 'navigation'],
      allowCookies: true,
      enableXRay: true,
    };

    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = 'us-east-1';
    rumClient = new AwsRum(appId, APPLICATION_VERSION, APPLICATION_REGION, config);
  } catch (error) {
    console.error(error);
  }
};

export const rumService = {
  init: () => initRum(getRumConfig()),

  // TODO: RUM currently can only log errors. This is a temporary solution until they release different logging levels
  logError: (e: Error | string) => {
    if (rumClient) {
      rumClient.recordError(`[ERROR]: ${e}`);
    }
  },

  logWarning: (message: string) => {
    if (rumClient) {
      rumClient.recordError(`[WARNING]: ${message}`);
    }
  },

  logInfo: (message: string) => {
    if (rumClient) {
      rumClient.recordError(`[INFO]: ${message}`);
    }
  },

  recordEvent: (eventName: string, payload: Record<string, any>) => {
    if (rumClient) {
      rumClient.recordEvent(eventName, payload);
    }
  },

  recordError: (error: Error, params?: { url: string }) => {
    if (rumClient) {
      rumClient.recordError(error);
    }
  },
};
