import { RumConfig } from './rumHelper';
import { HostNameMap, Stage } from './constants';

const ALPHA: RumConfig = {
  appId: '7ef184b5-84c5-4cf2-9983-cf816aa4fa9f',
  identityPoolId: 'us-east-1:6a51a6e1-d931-47e5-b806-b8136055f47c',
  guestRoleArn: 'arn:aws:iam::650927385512:role/RUM-Monitor-us-east-1-650927385512-5919864784861-Unauth',
};

const BETA: RumConfig = {
  appId: '8abffdb8-79b1-459d-8cc5-a457587c46ca',
  identityPoolId: 'us-east-1:38485721-9c53-4d97-b0a3-a4d405cb1094',
  guestRoleArn: 'arn:aws:iam::642238702480:role/AdsStatusDashboard-RUM-Monitor-us-east-1-642238702480-Unauth',
};

const PROD: RumConfig = {
  appId: '4444a1c6-3ee9-44fe-b172-07f53736d89c',
  identityPoolId: 'us-east-1:00328074-e715-4240-88fa-70f53ccc7c07',
  guestRoleArn: 'arn:aws:iam::569336552513:role/AdsStatusDashboard-RUM-Monitor-us-east-1-569336552513-Unauth',
};

export const getRumConfig = (): RumConfig => {
  switch (window.location.hostname.toLowerCase()) {
    case HostNameMap[Stage.LOCAL]:
      return ALPHA;
    case HostNameMap[Stage.ALPHA]:
      return ALPHA;
    case HostNameMap[Stage.BETA]:
      return BETA;
    case HostNameMap[Stage.PROD]:
      return PROD;
    default:
      return ALPHA;
  }
};
