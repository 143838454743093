import styled from 'styled-components';

export const FlexBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

export const IconsCss = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const FillCenterContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
`;

export const FillCenterContainerEventHistory = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
  margin-top: 20px;
  background-color: #f2f2f2;
`;

export const FillCenterEventHistoryHeader = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
  background-color: #f2f2f2;
  align-content: center;
`;

/* Sets minimum margin so small screens
 * will always have a little on the sides */
export const MinimumMargins = styled.div`
  margin-left: 3%;
  margin-right: 3%;
`;

export const MessageContainer = styled.div`
  height: 100%;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing.large};
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  max-width: 960px;
  padding: ${({ theme }) => theme.spacing.small};
`;

export const StyledFragment = styled.div`
  background-color: #f2f2f2;
`;
