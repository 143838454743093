import { Alert, Text } from '@amzn/storm-ui';
import styled from 'styled-components';
import { getIconByText, IconInterface } from '../icons/iconObject';
import React from 'react';
import { NO_KNOWN_ISSUES } from '../../config/constants';

export interface AlertProps {
  icon: IconInterface;
  description: string;
}

const AlertContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.small};
  margin-bottom: 30px;
`;

function AlertDisplay(props: AlertProps) {
  const { icon, description } = props;

  let isOpen = icon !== getIconByText(NO_KNOWN_ISSUES);
  return <>{icon && <AlertContainer>{displayIconIfRelevant(icon, description, isOpen)}</AlertContainer>}</>;
}

function displayIconIfRelevant(alertType: IconInterface, description: string, isOpen: boolean): JSX.Element {
  return (
    <Alert type={alertType.alertType} isOpen={isOpen}>
      <Text>{description}</Text>
    </Alert>
  );
}

export default AlertDisplay;
