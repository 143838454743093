import { defaultTheme } from '@amzn/storm-ui';
import { string } from 'prop-types';
import { Category, SubCategory } from '@amzn/ads_status_dashboard_website_common';

export const getBaseUrl = () => {
  if (
    window.location.hostname.toLowerCase().includes('localhost') ||
    window.location.hostname.toLowerCase().includes('dev-dsk')
  ) {
    return 'https://alpha.status.advertising.amazon.dev';
  }
  return window.location.origin;
};

export const JSON_URL = getBaseUrl() + '/status.json';
export const HISTORY_JSON_URL = getBaseUrl() + '/history.json';
export const HISTORY_TRANSLATED_JSON_URL = getBaseUrl() + '/translatedJson/history';
export const STATUS_TRANSLATED_JSON_URL = getBaseUrl() + '/translatedJson/status';

export const RegionExpandedToKey: {
  // @ts-ignore
  [string]: string;
} = {
  'North and Latin America': 'NA',
  'Europe, Middle East, and North Africa': 'EU',
  'Asia Pacific': 'FE',
};

export enum StatusTypeSeverity {
  'Degraded' = 1,
  'Disrupted' = 2,
  'Informational' = 3,
  'Resolved' = 4,
}

export enum Stage {
  ALPHA = 'alpha',
  BETA = 'beta',
  PROD = 'prod',
  LOCAL = 'local',
}

export const OUTAGE_CATEGORY = 'Status announcement';

export const categoryMap: { [key in Category]: SubCategory[] } = {
  'Sponsored ads': [
    SubCategory.CampaignCreation,
    SubCategory.CampaignManagement,
    SubCategory.AdDelivery,
    SubCategory.Bulksheets,
  ],
  'Amazon DSP': [
    SubCategory.CampaignCreation,
    SubCategory.CampaignManagement,
    SubCategory.CreativeManagement,
    SubCategory.AdDelivery,
    SubCategory.Bulksheets,
  ],
  'Measurement & Reporting': [SubCategory.Reports],
  'Brand content': [SubCategory.Stores, SubCategory.Posts],
  'Status announcement': [SubCategory.Outage],
  'Amazon Marketing Stream': [SubCategory.Streams],
};

const newCategories = [Category.AMS];
const newSubcategories = [SubCategory.Bulksheets, SubCategory.Streams];

export const categoryMapForProduction = Object.keys(categoryMap).reduce((aggr, key) => {
  const category = key as Category;
  if (newCategories.includes(category)) {
    return aggr;
  } else {
    const subcategories = categoryMap[category].filter((subcategory) => !newSubcategories.includes(subcategory));

    return {
      ...aggr,
      [category]: subcategories,
    };
  }
}, {});

export const categoryMapForDisplay = categoryMap;

export const categoriesForDisplay = Object.keys(Category);

export const HostNameMap: { [key in Stage]: string } = {
  [Stage.LOCAL]: 'alpha.status.advertising.amazon.dev',
  [Stage.ALPHA]: 'alpha.status.advertising.amazon.dev',
  [Stage.BETA]: 'beta.status.advertising.amazon.dev',
  [Stage.PROD]: 'status.ads.amazon.com',
};

export const isLocalizationEnabled: { [key in Stage]: boolean } = {
  [Stage.LOCAL]: true,
  [Stage.ALPHA]: true,
  [Stage.BETA]: true,
  [Stage.PROD]: true,
};

export const PlatformMap = {
  'api and console': 'API and console',
  'console and api': 'API and console',
  'api only': 'API only',
  'console only': 'console only',
};

export const DEFAULT_ICON_TYPE = 'check';
export const DEFAULT_ICON_COLOR = defaultTheme.palette.green[700];
export const NO_KNOWN_ISSUES = 'No known issues';

export function showHistory(): boolean {
  return !window.location.href.toLowerCase().includes('preview');
}

export const DEFAULT_EVENT_HISTORY = {
  hashedId: '',
  region: '',
  category: '',
  subcategory: '',
  currentStatus: '',
  statusMessages: [],
  systemImpacted: '',
  endTime: '',
  startTime: '',
  url: '',
};

export const LOCALES = {
  ENGLISH: 'en-US',
  CHINESE: 'zh-CN',
  GERMAN: 'de-DE',
  ENGLISH_CA: 'en-CA',
  ENGLISH_IN: 'en-IN',
  ENGLISH_GB: 'en-GB',
  ENGLISH_SG: 'en-SG',
  ENGLISH_AU: 'en-AU',
  SPANISH_ES: 'es-ES',
  SPANISH_MX: 'es-MX',
  FRENCH_CA: 'fr-CA',
  FRENCH: 'fr-FR',
  ITALIAN: 'it-IT',
  NETHERLANDS: 'nl-NL',
  JAPANESE: 'ja-JP',
  POLISH: 'pl-PL',
  PORTUGUESE: 'pt-BR',
  SWEDISH: 'sv-SE',
  VIETNAMESE: 'vi-VN',
  TURKISH: 'tr-TR',
  ARABIC: 'ar-AE',
  HINDI: 'hi-IN',
  TAMIL: 'ta-IN',
  THAI: 'th-TH',
  KOREAN: 'ko-KR',
  CHINESE_TAIWAN: 'zh-TW',
};

export const languages = [
  { name: 'English (US)', code: LOCALES.ENGLISH },
  { name: '中文（简体)', code: LOCALES.CHINESE },
  { name: 'Deutsch', code: LOCALES.GERMAN },
  { name: 'English (Canada)', code: LOCALES.ENGLISH_CA },
  { name: 'English (India)', code: LOCALES.ENGLISH_IN },
  { name: 'English (UK)', code: LOCALES.ENGLISH_GB },
  { name: 'English (Singapore)', code: LOCALES.ENGLISH_SG },
  { name: 'English (Australia)', code: LOCALES.ENGLISH_AU },
  { name: 'Español (España)', code: LOCALES.SPANISH_ES },
  { name: 'Español (Mexico)', code: LOCALES.SPANISH_MX },
  { name: 'Français (Canada)', code: LOCALES.FRENCH_CA },
  { name: 'Français (France)', code: LOCALES.FRENCH },
  { name: 'Italiano', code: LOCALES.ITALIAN },
  { name: 'Nederlands', code: LOCALES.NETHERLANDS },
  { name: 'Polski', code: LOCALES.POLISH },
  { name: 'Português', code: LOCALES.PORTUGUESE },
  { name: 'Svenska', code: LOCALES.SWEDISH },
  { name: 'Tiếng Việt', code: LOCALES.VIETNAMESE },
  { name: 'Turkce', code: LOCALES.TURKISH },
  { name: '日本語', code: LOCALES.JAPANESE },
  { name: 'العربية', code: LOCALES.ARABIC },
  { name: 'हिन्दी', code: LOCALES.HINDI },
  { name: 'தமிழ்', code: LOCALES.TAMIL },
  { name: 'ไทย', code: LOCALES.THAI },
  { name: '한국어', code: LOCALES.KOREAN },
  { name: '中文（繁體)', code: LOCALES.CHINESE_TAIWAN },
];

export const RTL_LOCALES = new Set(['ar-AE']); // Add more RTL locales as needed

export const reverseLanguageMap = {
  [LOCALES.ENGLISH]: 'English (US)',
  [LOCALES.CHINESE]: '中文（简体)',
  [LOCALES.GERMAN]: 'Deutsch',
  [LOCALES.ENGLISH_CA]: 'English (Canada)',
  [LOCALES.ENGLISH_IN]: 'English (India)',
  [LOCALES.ENGLISH_GB]: 'English (UK)',
  [LOCALES.ENGLISH_SG]: 'English (Singapore)',
  [LOCALES.ENGLISH_AU]: 'English (Australia)',
  [LOCALES.SPANISH_ES]: 'Español (España)',
  [LOCALES.SPANISH_MX]: 'Español (Mexico)',
  [LOCALES.FRENCH_CA]: 'Français (Canada)',
  [LOCALES.FRENCH]: 'Français (France)',
  [LOCALES.ITALIAN]: 'Italiano',
  [LOCALES.NETHERLANDS]: 'Nederlands',
  [LOCALES.POLISH]: 'Polski',
  [LOCALES.PORTUGUESE]: 'Português',
  [LOCALES.SWEDISH]: 'Svenska',
  [LOCALES.VIETNAMESE]: 'Tiếng Việt',
  [LOCALES.TURKISH]: 'Turkce',
  [LOCALES.JAPANESE]: '日本語',
  [LOCALES.ARABIC]: 'العربية',
  [LOCALES.HINDI]: 'हिन्दी',
  [LOCALES.TAMIL]: 'தமிழ்',
  [LOCALES.THAI]: 'ไทย',
  [LOCALES.KOREAN]: '한국어',
  [LOCALES.CHINESE_TAIWAN]: '中文（繁體)',
};
