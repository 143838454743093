import { defaultTheme } from '@amzn/storm-ui';

export type AlertType = 'info' | 'warning' | 'error' | 'success';

export type StatusIconText = 'Informational' | 'Degraded' | 'Disrupted' | 'No known issues';

export interface IconInterface {
  readonly type: string;
  readonly text: string;
  readonly alertType: AlertType;
  readonly color: string;
}

// Note, the order of alert types is important as it is what will
// be displayed at the top of the Category based on Subcategory results
export const Icons: IconInterface[] = [
  {
    type: 'check',
    text: 'No known issues',
    alertType: 'success',
    color: defaultTheme.palette.green[700],
  },
  {
    type: 'info-circle',
    text: 'Informational',
    alertType: 'info',
    color: defaultTheme.palette.blue[700],
  },
  {
    type: 'exclamation-triangle',
    text: 'Degraded',
    alertType: 'warning',
    color: defaultTheme.palette.clementine[500],
  },
  {
    type: 'exclamation',
    text: 'Disrupted',
    alertType: 'error',
    color: defaultTheme.palette.red[600],
  },
];

/**
 * Returns the icon but if it is not found, then returns "No Known Issues" icon
 * @param type StatusIconText to correlate with Icon text
 * @returns Icon of that AlertType
 */
export function getIconByText(type: StatusIconText): IconInterface {
  const icon = Icons.find((icon) => icon.text === type);
  return icon ? icon : Icons[0];
}

/**
 * If the index of the first icon provided is greater than or equal to the second, than return icon 1 as
 * it will be considered more severe of a notification than icon 2
 * @param icon1
 * @param icon2
 * @returns the icon that is most severe (e.g. success < info < warning < error)
 */
export function returnMostSevereIcon(icon1: IconInterface, icon2: IconInterface): IconInterface {
  if (
    Icons.findIndex((x) => x.alertType === icon1.alertType) >= Icons.findIndex((x) => x.alertType === icon2.alertType)
  ) {
    return icon1;
  }

  return icon2;
}
