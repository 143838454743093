import { Text } from '@amzn/storm-ui';
import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  align-items: center;
  background-color: #232f3f;
  height: 60px;
  font-size: 13px;
  color: white;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const AmazonAdsLogo = styled.img`
  width: 104px;
  height: 26px;
  margin-inline-start: ${({ theme }) => theme.spacing.medium};
  margin-inline-end: ${({ theme }) => theme.spacing.medium};
  margin-block-end: ${({ theme }) => theme.spacing.base};
  margin-block-start: ${({ theme }) => theme.spacing.medium};
`;

export const HeaderText = styled(Text)`
  padding-inline-start: ${({ theme }) => theme.spacing.medium};
  font-weight: 700;
`;

export const LanguageDropdownContainer = styled.div`
  padding-inline-start: ${({ theme }) => theme.spacing.medium};
  font-weight: 700;
  position: absolute;
  inset-inline-end: ${({ theme }) => theme.spacing.medium};
  top: 50%;
  transform: translateY(-50%);
  color: white;
`;
