import React, { useState, useRef } from 'react';
import { DropdownItem, PortalContextProvider } from '@amzn/storm-ui';
import { languages } from '../../config/constants';
import {
  FlexContainer,
  Wrapper,
  Switcher,
  DropdownContainer,
  StyledDropdown,
  ChangeLangButton,
  LanguageLabel,
} from './languageSwitcherCss';
import { FormattedMessage } from 'react-intl';

export function LanguageSwitcher({ currentLocale, onLanguageChange, onClose }) {
  const [selectedLocale, setSelectedLocale] = useState(currentLocale);
  const dropdownRef = useRef(null);
  const portalRef = useRef(null);
  const handleLangSelection = (lang) => setSelectedLocale(lang);

  const handleLanguageChange = () => {
    onLanguageChange(selectedLocale);
    onClose();
  };

  const LanguageSwitcherOptions = languages.map(({ name, code }) => (
    <DropdownItem key={code} value={code}>
      {name}
    </DropdownItem>
  ));

  return (
    <Switcher id="LanguageSwitcher">
      <LanguageLabel>
        <FormattedMessage id="language" />
      </LanguageLabel>
      <FlexContainer>
        <DropdownContainer ref={portalRef} />
        <Wrapper>
          <PortalContextProvider portalElementRef={portalRef}>
            <StyledDropdown
              ref={dropdownRef}
              noWrapDropdownItems
              label=" "
              name="aac-select-language"
              data-takt-id="aac-select-language"
              selectedValue={selectedLocale}
              onChange={handleLangSelection}
              small={true}
            >
              {LanguageSwitcherOptions}
            </StyledDropdown>
          </PortalContextProvider>
          <ChangeLangButton
            disabled={selectedLocale === currentLocale}
            onClick={handleLanguageChange}
            small={true}
            data-takt-id="aac-language-switch-btn"
          >
            <FormattedMessage id="change-language" />
          </ChangeLangButton>
        </Wrapper>
      </FlexContainer>
    </Switcher>
  );
}

export default LanguageSwitcher;
